<template>
  <div>
      <div style="display: flex;margin-bottom: 15px;width:100%;height: 50px;" :style="`justify-content:${styles?'space-between':'flex-end'}`">
        <div v-if="styles">
          <v-btn color="primary" @click="toggleStyles"
                 outlined
                 small
                 dense
          ><v-icon>mdi-chevron-left</v-icon>
            <span>Back to content</span></v-btn
          >
        </div>
<!--          <v-menu offset-y open-on-hover bottom>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">-->
<!--                  <v-icon>mdi-cog</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <v-list>-->
<!--                  <v-list-item>-->
<!--                      <v-list-item-title-->
<!--                              style="cursor: pointer" @click="toggleStyles"-->
<!--                      > {{styles?'Content':'Style'}} settings</v-list-item-title-->
<!--                      >-->
<!--                  </v-list-item>-->
<!--                <v-list-item>-->
<!--                  <v-list-item-title-->
<!--                      style="cursor: pointer" @click="openCopy"-->
<!--                  >Copy content from {{landing.advanced.default_lang}}</v-list-item-title-->
<!--                  >-->
<!--                </v-list-item>-->
<!--              </v-list>-->
<!--          </v-menu>-->
      </div>
      <div v-if="styles">
          <template>
              <TypeEventsStyles :styles="content.styles"/>
          </template>
      </div>
      <div v-if="!styles">
          <Editor
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="content.text"
          >
          </Editor>
          <p style="margin: 15px 0;">
              To display the calendar, fill in the
              <router-link :to="`/events?id=${$route.params.id}`">events</router-link>
          </p>
      </div>
    <PopupConfirm ref="PopupConfirm"  @confirm="confirmCopy" :title="`Are you sure about copy content from ${defaultLangName} to ${currentLangName}`"/>
  </div>
</template>

<script>

import TypeEventsStyles from "./typeEventsStyles";
import {mapState} from "vuex";
export default {
  name: "typeEvent",
  components: {
    TypeEventsStyles,
    PopupConfirm:() => import("@/components/Popup/PopupConfirm")
  },
  props:["content","item"],
  computed:{
    ...mapState(['landing']),
    currentLangName(){
      return this.landing.advanced.langs.find(x => x.value === this.landing.current_lang).name
    },
    defaultLangName(){
      return this.landing.advanced.langs.find(x => x.value === this.landing.advanced.default_lang).name
    },
  },
  data() {
    return {
        styles:false,
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 400,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_rel',
                                  label: 'Rel',
                                  items: [
                                      {text: 'No value', value: ''},
                                      {text: 'Alternate', value: 'alternate'},
                                      {text: 'Help', value: 'help'},
                                      {text: 'Manifest', value: 'manifest'},
                                      {text: 'No follow', value: 'nofollow'},
                                      {text: 'No opener', value: 'noopener'},
                                      {text: 'No referrer', value: 'noreferrer'},
                                      {text: 'Opener', value: 'opener'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
    };
  },
    methods:{
      confirmCopy(){
        this.item.content[this.landing.current_lang][7] = JSON.parse(JSON.stringify(this.item.content[this.landing.advanced.default_lang][7]));
        this.forceRerender();
      },
      forceRerender() {
        // Removing my-component from the DOM
        this.$store.state.renderComponent = false;

        this.$nextTick(() => {
          // Adding the component back in
          this.$store.state.renderComponent = true;
        });
      },
      openCopy(){
        this.$refs.PopupConfirm.open();
      },
        toggleStyles(){
            this.styles = !this.styles;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
