<template>
    <div>
        <div>
            <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Name settings</label>
            </div>
          <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="margin-bottom: 0;margin-right: 8px;width: 200px;">
                Font Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    z-index="999"
                    :close-on-content-click="false"
                    v-model="show_name_text_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${styles.name_color}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="styles.name_color"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px">
              <label class="label" style="min-width:200px;display:block;margin-bottom:0">
                Font Size <b>{{ styles.name_font_size }}px</b>
              </label>
              <div class="slider" style="width:100%;">
                <v-slider
                    hide-details
                    :max="40"
                    :min="10"
                    v-model="styles.name_font_size"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px">
              <label class="label" style="min-width:200px;display:block;margin-bottom:0">
                Font Weight <b>{{ parseInt(styles.name_font_weight, 10) / 10 }}%</b>
              </label>
              <div class="control" style="width:100%">
                <v-slider
                    hide-details
                    :max="1000"
                    :min="100"
                    v-model="styles.name_font_weight"
                    :step="100"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;margin-bottom:15px">
              <div style="display: flex;align-items:center;">
                <label class="label" style="margin-bottom: 0;margin-right: 8px;width: 200px;">
                  Font Style <b>{{ styles.name_font_style }}</b>
                </label>
                <div class="control">
                  <v-radio-group hide-details row v-model="styles.name_font_style">
                    <v-radio
                        v-for="(type,typeIndex) in fontStyleTypes"
                        :key="type.id"
                        :label="type.label"
                        :value="type.value"
                        :style="`margin-left:${typeIndex?'15px':0}`"
                    >
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
              <div style="display: flex;align-items:center;">
                <label class="label" style="margin-bottom: 0;margin-right: 8px;width: 200px;">
                  Change Font Family
                </label>

                <v-select
                    flat
                    dense
                    outlined
                    hide-details
                    placeholder="Select Font Family"
                    :items="fontFamilyTypes"
                    item-text="label"
                    item-value="value"
                    class="canvas__select"
                    v-model="styles.name_font_family"
                >
                  <template v-slot:selection="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>


        </div>

        <div>
            <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Date settings</label>
            </div>
            <div style="padding:0 15px;">
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;margin-right: 8px;width: 200px;">
                  Background Color
                </label>
                <div class="control">
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="show_date_background_color"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="custom__menu--color"
                          :style="`background-color:${styles.date_background}`"
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="styles.date_background"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;margin-right:8px;width: 200px;">
                  Text Color
                </label>
                <div class="control">
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="show_date_text_color"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="custom__menu--color"
                          :style="`background-color:${styles.date_color}`"
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="styles.date_color"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
        </div>

        <div>
            <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Button settings</label>
            </div>
          <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="margin-bottom: 0;margin-right: 8px;width: 200px;">
                Text Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_button_text_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${styles.button_color}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="styles.button_color"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="margin-bottom: 0;margin-right: 8px;width: 200px;">
                Background Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_button_background_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${styles.button_background}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="styles.button_background"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="margin-bottom: 0;margin-right: 8px;width: 200px;">
                Border Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_button_border_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${styles.button_border}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="styles.button_border"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
          </div>


        </div>
        <div>
            <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Image settings</label>
            </div>
          <div style="padding:0 15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="display:block;margin-bottom:0;min-width:200px;">
                List Height <b>{{ styles.list_image_height }} px</b>
              </label>
                <div class="slider" style="width:100%">
                  <v-slider
                      hide-details
                      :max="300"
                      :min="0"
                      v-model="styles.list_image_height"
                  ></v-slider>
                </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="display:block;margin-bottom:0;min-width:200px;">
                Detail Height <b>{{ styles.detail_image_height }} px</b>
              </label>
              <div class="slider" style="width:100%">
                <v-slider
                    hide-details
                    :max="300"
                    :min="0"
                    v-model="styles.detail_image_height"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "typeEventsStyles",
        props:['styles'],
        data(){
            return{
                fontStyleTypes: [
                    { id: 1, value: "normal", label: "Normal" },
                    { id: 0, value: "italic", label: "Italic" },
                ],
                fontFamilyTypes: [
                    { id: 0, value: "Arial", label: "Arial" },
                    { id: 1, value: "SF UI Text", label: "SF UI Text" },
                    { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
                    { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
                    { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
                    { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
                    { id: 6, value: "Geometria", label: "Geometria" },
                    { id: 7, value: "DIN Pro", label: "DIN Pro" },
                    { id: 8, value: "Circe", label: "Circe" },
                    { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
                    { id: 10, value: "Acrom", label: "Acrom" },
                    { id: 11, value: "Inter", label: "Inter" },
                ],
                show_date_text_color:false,
                show_date_background_color:false,
                show_date_border_color:false,
                show_name_text_color:false,
                show_button_border_color:false,
                show_button_text_color:false,
                show_button_background_color:false,
            }
        }
    }
</script>

<style scoped>

</style>